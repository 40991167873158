<template>
  <div class="shipping-details">
    <h3 class="shipping__title">
      {{ mode === "pickup" ? $t("stopsInfo.origin") : $t("stopsInfo.delivery") }}
    </h3>
    <h4 class="shipping__subtitle">
      {{
        mode === "pickup"
          ? (isDrayage ? 'Port' : $t("stopsInfo.pickupLocation"))
          : $t("stopsInfo.deliveryLocation")
      }}
    </h4>
    <b-overlay :show="updatingLocation" rounded="sm">
      <b-overlay :show="loadingContacts" rounded="sm">
        <div
          v-if="isDrayage
          &&  mode === 'pickup'">
         <RCInput
            class="shipping__text-field reference-input"
            type="text"
            label="Port"
            textField="name"
            v-model="shippingInformation.name"
            :floatLabel="true"
            :disabled="true"
          />
        </div>
        <div v-else class="account-list-search" >
          <RCSearcher
            class="account-list"
            v-model="location"
            @search="searchLocations($event)"
            @selectOption="selectOption($event, 'account')"
            label="Choose a location"
            :delay="0"
            :suggestions="suggestions"
            :forceSelection="false"
            :floatLabel="true"
            :requestLength="3"
            :dropdown="true"
            :requiredField="true"
            :disabled="true"
            ref="account-searcher"
            textField="account_name"
            :key="remountSearcher"
          >
            <template v-slot:icon>
              <i class="ion ion-book location-icon"></i>
            </template>
            <template v-slot:suggestions-action>
              <div class="button-content" @mousedown="openAccountCreationModal">
                <i class="ion ion-add-outline"></i>
                <span>{{ $t("stopsInfo.createStopInfo.createNewLocation") }}</span>
              </div>
            </template>
            <template v-slot:section-title="slotProps">
              <div class="list-content list-content--section">
                {{ slotProps.section.sectionName }}
              </div>
            </template>
            <template v-slot:suggestion-item="slotProps">
              <div class="list-content-hover">
                <div class="list-content list-content--label">
                  <p>{{ slotProps.suggestion.name }}</p>
                  <i class="ion ion-location-outline"></i>
                  <span>{{
                    slotProps.suggestion.state_code + " " + slotProps.suggestion.city
                  }}</span>
                  <p class="list-content--street">
                    {{ slotProps.suggestion.street }}
                  </p>
                </div>
              </div>
            </template>
          </RCSearcher>
        </div>

      </b-overlay>
      <div class="account-detail" v-if="accountInfo">
        <div class="account-detail__information">
          <i class="ion ion-location-outline inline-icon"></i>
          <span class="account-detail__name">{{ accountInfo.street }}</span>
          <p class="account-detail__address">
            {{ formatAccountName(accountInfo) }}
          </p>
        </div>
      </div>
      <ContactDetail
        :contactInfo="contactInfo"
        :contactsByAccount="contactsList"
        :selectedAccount="accountInfo"
        @updateContact="getContactData"
        :disableModification="mode === 'pickup' && isDrayage"
      />
    </b-overlay>
    <b-modal
      v-model="showContactModal"
      centered
      hide-header
      hide-footer
      size="md"
      no-close-on-backdrop
    >
      <SelectContactModal
        @closeModal="showContactModal = false"
        @updateContact="getContactData"
        @cancelSelectContact="cancelSelectContact"
        @getAccountData="getAccountData"
        @getContactData="getContactData"
        :contactsByAccount="contactsList"
        :selectedAccount="accountInfo"
      />
    </b-modal>

    <b-modal
      centered
      class="account-edit-modal"
      v-model="modalEditShow"
      hide-header
      hide-footer
      size="md"
      no-close-on-backdrop
    >
      <AccountEditModal
        @closeModal="closeModal"
        @getAccountData="getAccountData"
        @getContactData="getContactData"
        :locationInformation="accountInfo"
        :contactInformation="contactInfo"
        :shippingInformation="shippingInformation"
        :mode="mode"
      />
    </b-modal>
    <ShippingInfo
      @references="getReferences"
      :shippingInfo="locationInformation || shippingInformation"
      ref="shipping-info"
      :mode="mode"
      v-model="shippingInfo"
      :transitTime="transitTime"
      :references="references"
      :limitIntructionsCharacters="limitIntructionsCharacters"
      :selectedCarrier="selectedCarrier"
      :isDrayage=" isDrayage"
    />
  </div>
</template>

<script>
import { Axios } from "axios";
import * as yup from "yup";
import RCSearcher from "../../../../components/RCComponents/CustomRCSearcher.vue";
import SelectContactModal from "../../shared/AccountCreation/SelectContactModal.vue";
import AccountEditModal from "../../shared/AccountCreation/CreateAccountModal.vue";
import ContactDetail from "./ContactDetail.vue";
import ShippingInfo from "./ShippingInfo.vue";
import RCInput from "../../../../components/RCComponents/CustomRCInput.vue";

export default {
  /* eslint-disable operator-linebreak */
  /* eslint-disable implicit-arrow-linebreak */
  /* eslint-disable camelcase */
  name: "StopInformation",
  props: {
    limitIntructionsCharacters: Boolean,
    mode: String,
    locationInformation: Object,
    contactInformation: Object,
    shippingInformation: Object,
    isDrayage: Boolean,
    transitTime: {
      type: String,
      required: false,
    },
    selectedCarrier: Object
  },
  components: {
    RCSearcher,
    SelectContactModal,
    ShippingInfo,
    AccountEditModal,
    ContactDetail,
    RCInput
  },
  data() {
    return {
      contactsList: [],
      accountInfo: null,
      contactInfo: null,
      shippingInfo: null,
      locationInfo: {},
      location: null,
      suggestions: [
        // Comentado hasta nuevo aviso - NO BORRAR
        // { sectionName: "Magaya Clients", options: [] },
        { sectionName: "My Locations", options: [] },
      ],
      filteredLocations: [],
      modalEditShow: false,
      zipcode: null,
      loadingContacts: false,
      updatingLocation: false,
      validate: false,
      missingFields: false,
      remountSearcher: 0,
      indexedDBInstance: null,
      references: null,
      remountSearcherButton: 0,
      showContactModal: false,
    };
  },
  watch: {
    /* Residential Locations */
    accountInfo: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit("accountInformation", this.accountInfo);
      }
    },
    locationInformation: {
      immediate: true,
      handler() {
        this.$emit("accountInformation", this.locationInformation);
      }
    }
  },
  async created() {
    Object.keys(this.shippingInformation).forEach((key) => {
      this.locationInfo[key] = this.shippingInformation[key];
    });
    if (this.locationInformation) {
      this.accountInfo = this.locationInformation.shipping_address;
      this.accountInfo.location_id = this.locationInformation.id;
      this.accountInfo.name = this.locationInformation.name;
    }
    this.contactInfo = this.contactInformation ? { ...this.contactInformation } : null;
    if (this.accountInfo) {
      this.zipcode = this.accountInfo.postal_code;
      this.location = { ...this.accountInfo };
      this.location.account_name = this.accountInfo.name;
    } else {
      this.zipcode = this.shippingInformation.zip_code;
    }
    this.references = {
      references: this.shippingInformation.references,
      instructions: this.shippingInformation.instructions,
    };
    this.filterAccountsByZipcode();
    if (this.accountInfo) {
      this.getContactsList(this.accountInfo.location_id);
    }
  },
  methods: {
    async getContactsList(id) {
      if (this.$options.filters.checkPermission("get:salesforce-contacts")) {
        const data = {
          params: {
            account_id: id,
          },
        };
        this.contactsList = await this.$store.dispatch(
          "load/stops/getSalesforceContactsByAccount",
          data
        );
      }
    },
    stopFieldsValidation() {
      const shippingFields = this.$refs["shipping-info"].validateFormfields();
      if (shippingFields && this.accountInfo && this.contactInfo) {
        const data = {
          accountData: this.accountInfo,
          contactData: this.contactInfo,
          shippingData: {
            ...this.shippingInfo,
          },
          id: this.shippingInformation.id,
        };
        this.validate = true;
        this.$emit("getStopData", data, this.mode);
      } else if (!this.contactInfo) {
        this.validate = false;
        this.missingFields = true;
        this.missingFieldsAlert(`Missing ${this.mode} location`);
      } else if (!this.accountInfo) {
        this.validate = false;
        this.missingFields = true;
        this.missingFieldsAlert(`Missing ${this.mode} location`);
      } else if (!shippingFields) {
        this.validate = false;
        this.missingFields = true;
        this.missingFieldsAlert(this.$t("stopsInfo.createStopInfo.missingShippingFields"));
      }
      return this.validate;
    },
    missingFieldsAlert(data) {
      this.swal({
        title: data,
        text: "Please, try again.",
        icon: "error",
        dangerMode: true,
      });
    },
    filterAccountsByZipcode() {
      this.filteredLocations = this.$store.getters["load/stops/getSalesforceAccounts"]
        .filter((account) => account.zip_code === this.zipcode)
        .map((account) => ({
          ...account,
          label: `${account.name}, ${account.zip_code}`,
        }));
      // Cambiar cuando se organice lo de magaya
      // this.suggestions[1].options = [...this.filteredLocations];
      this.suggestions[0].options = [...this.filteredLocations];
    },
    cancelAxiosRequest() {
      const source = Axios.CancelToken.source();
      this.$store.commit("setCancelToken", source);
      this.$store.commit("cancelToken", source.cancel);
      this.$store.commit("setCancelRequest", source.cancel);
    },
    async searchLocations(event) {
      if (!this.account) {
        if (event.query.length > 2) {
          this.cancelAxiosRequest();
          // Comentado hasta nuevo aviso - NO BORRAR
          // const magayaClients = await this.$store.dispatch("load/stops/getMagayaClients", {
          //   params: { name: event.query },
          // });
          // if (magayaClients) {
          //   this.suggestions[0].options = this.buildStopSelectorLocations(magayaClients).filter(
          //     (item) => item.zip_code === this.zipcode
          //   );
          // } else {
          //   this.suggestions[0].options = [];
          // }
        }
        // Cambiar cuando se organice lo de magaya
        // this.suggestions[1].options = this.filteredLocations.filter(
        //   (account) =>
        //     account.city.toLowerCase().includes(event.query.toLowerCase()) ||
        //     account.name.toLowerCase().includes(event.query.toLowerCase())
        // );
        this.suggestions[0].options = this.filteredLocations.filter(
          (account) =>
            account.city.toLowerCase().includes(event.query.toLowerCase()) ||
            account.name.toLowerCase().includes(event.query.toLowerCase())
        );
      }
      this.remountSearcherButton += 1;
    },
    buildStopSelectorLocations(locations) {
      return locations
        .map((element) => ({
          ...element,
          label: `${element.name}, ${element.zip_code}`,
        }))
        .filter((item) => item.zip_code && item.state_code && item.city && item.country_code);
    },
    async selectOption(event) {
      this.accountInfo = { ...event };
      this.location.account_name = this.accountInfo.name;
      this.$refs["account-searcher"].hideDropdown();
      this.loadingContacts = true;
      this.$store.commit("load/tender/setRequestInProcess", true);
      if (event.guid) {
        if (!this.validateLocationPropsFormat(event)) {
          this.modalEditShow = true;
          this.loadingContacts = false;
          this.$store.commit("load/tender/setRequestInProcess", false);
          return;
        }
        await this.getContactsByMagayaClient(event.guid);
      } else {
        await this.getContactsList(event.id_salesforce);
      }
      this.loadingContacts = false;
      this.$store.commit("load/tender/setRequestInProcess", false);
      this.showContactModal = true;
    },
    async getContactsByMagayaClient(guid) {
      this.contactsList = await this.$store.dispatch("load/stops/getContactsByMagayaClient", {
        params: { guid },
      });
    },
    validateLocationPropsFormat(location) {
      const contactSchema = yup.object().shape({
        name: yup.string().required(),
        city: yup.string().required(),
        country_code: yup.string().required(),
        state_code: yup.string().required(),
        street: yup.string().required(),
        zip_code: yup.string().required(),
      });
      const isValid = contactSchema.isValidSync(location, {
        abortEarly: false,
      });
      return isValid;
    },
    cancelSelectContact() {
      this.location = null;
      this.accountInfo = null;
      this.contactInfo = null;
      this.remountSearcher += 1;
      this.showContactModal = false;
    },
    getAccountData(data) {
      this.accountInfo = data;
      this.location = { ...this.accountInfo };
      this.location.account_name = this.accountInfo.name;
    },
    getContactData(data) {
      this.contactInfo = data;
      this.updateLocationStop(data);
    },
    closeModal(data) {
      this.modalEditShow = data;
    },
    formatAccountName(accountInfo) {
      const account = `${accountInfo.city}, ${accountInfo.state_code}, ${
        accountInfo.postal_code || accountInfo.zip_code
      }`;
      return account;
    },
    openAccountCreationModal() {
      this.modalEditShow = !this.modalEditShow;
      this.$refs["account-searcher"].hideDropdown();
    },
    async updateLocationStop(data) {
      if (this.$options.filters.checkPermission("patch:stop")) {
        const payload = {
          load_id: this.$route.params.loadId,
          stop_id: this.shippingInformation.id,
          body: {
            location_id: this.accountInfo.id_salesforce,
            contact_info_id: data.id,
            city: this.accountInfo.city,
            state_code: this.accountInfo.state_code,
            zip_code: this.accountInfo.zip_code,
            country_code: this.accountInfo.country_code,
            country: this.accountInfo.country,
            street: this.accountInfo.street,
          },
          contact: {
            ...data,
          },
        };
        this.updatingLocation = true;
        this.$store.commit("load/tender/setRequestInProcess", true);
        const response = await this.$store.dispatch("load/stops/updateStop", payload);
        payload.body.name = response.stop.location_name;
        this.$store.commit("load/updateAccountsAndContactsLists", payload);
        this.$store.commit("load/updateStopInformation", response.stop);
        this.$store.commit("load/tender/setRequestInProcess", false);
        this.updatingLocation = false;
      }
    },
    getReferences(data) {
      this.$emit("getReferences", data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/components/Modal.scss";

.shipping__title {
  font-weight: 700;
  padding-bottom: 20px;
  border-bottom: 1px solid $color-border-container;
  @include font-section-title;
  color: $color-primary-title;
}
.shipping__subtitle {
  color: $color-border-container;
  @include font-standart-text;
  font-weight: 600;
  margin: 25px 0px 25px 0px;
}
::v-deep .account-list-search {
  margin: 20px auto;
  & .search-input {
    border: 1px solid $color-border-container;
    display: flex;
    align-items: center;
    @include font-standart-text;
  }
  & .dropdown {
    z-index: 3000;
  }
}

.account-detail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px auto 0px auto;
  @include font-standart-text;
  &__information {
    color: $color-primary-company;
  }
  &__icon {
    cursor: pointer;
    color: $color-border-container;
    font-size: 1.2rem;
  }
  &__address {
    margin-left: 20px;
    margin-bottom: 7px;
  }
}

::v-deep .inline-icon {
  font-size: 18px;
  margin-right: 3px;
  color: $color-border-container;
}
.location-icon {
  color: $color-border-container;
}
.button-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: auto;
  padding: 5px 0px;
  color: $color-primary-company;
  border-bottom: 1px solid $color-border-container;
  text-align: center;
  cursor: pointer;
  i {
    font-size: 30px;
    margin-right: 25px;
  }
  span {
    font-weight: bold;
  }
}
.list-content {
  cursor: pointer;
  color: $color-primary-company;
  width: 90%;
  margin: auto;
  padding: 5px;
  p {
    margin: 0px;
    @include font-small-standart-text;
  }
  i {
    color: $color-border-container;
    margin-right: 7px;
  }
  span {
    margin: 0px;
    @include font-small-standart-text;
  }
  &--section {
    color: $color-border-container;
    @include font-small-information;
  }
}
.list-content--street {
  font-weight: 500;
  color: rgb(180, 177, 177);
}
.list-content-hover {
  :hover {
    background-color: #e8ecee;
  }
}

::v-deep .modal-content {
  width: 100% !important;
  @media (max-width: 500px) {
    width: 60% !important;
  }
}
::v-deep .search-input__input {
  @include font-standart-text;
}
</style>
