<template>
  <div class="line-items-container">
    <LineItemsSkeleton v-if="loading" />
    <template
      v-if="chosenLoad.mode_id === modes.ftl.modeId || chosenLoad.mode_id === modes.reefer.modeId"
    >
      <LineItemsFtl
        v-if="screenWidth > 800 || currentStep === 'lineItems'"
        :lineItems="selectedLoadLineItems"
        :chosenLoad="chosenLoad"
        :isHazmat="isHazmat"
        :key="key"
        :quoteMode="chosenLoad.mode_id === modes.ftl.modeId ? 'ftl' : 'reefer'"
        @emitLineItems="getLineItems"
        ref="lineItemsFTL"
      />
      <div class="line-items-information" v-if="screenWidth > 800 || currentStep === 'lineItems'">
        <LineItemsResume
          :tenderMode="chosenLoad.mode_id"
          :quoteMode="chosenLoad.mode_id === modes.ftl.modeId ? 'ftl' : 'reefer'"
          :isTenderView="true"
        />
        <AlertValidationMessage
          :lineItemsMessageError="lineItemsMessageError"
          :quoteMode="chosenLoad.mode_id === modes.ftl.modeId ? 'ftl' : 'reefer'"
          v-if="lineItemsMessageError.length > 0"
          class="mb-4"
        />
      </div>
    </template>
    <template v-else-if="chosenLoad.mode_id === modes.drayage.modeId || chosenLoad.mode_id === modes.drayage.modeId">
      <div>
        <el-alert
        class="alert-container__drayage"
        :closable="false"
      >
      If you need to change this information you´d need to re quote the whole shipment
      </el-alert>
      </div>
      <div class="drayage-container-items">
        <LineItemsDrayage
          ref="lineItemsDrayage"
          :lineItems="chosenLoad.line_items"
          :disabled="true"
          :selectedCarrier="selectedCarrier" />
      </div>
    </template>
    <template v-else>
      <div v-if="showDesktopLineItems">
        <LineItems
          :showFeatureSearchItemAndAddMoreItems="true"
          :data="lineItemsData"
          :isHazmat="isHazmat"
          :completeData="chosenLoad.line_items"
          :chosenLoad="chosenLoad"
          :lineItemsFields="lineItemsFields"
          :isReefer="isReefer"
          ref="lineItems"
        />
      </div>
      <div v-else-if="showResponsiveLineItems || currentStep === 'lineItems'">
        <ResponsiveLineItems
          v-if="chosenLoad.mode_id !== modes.ftl.modeId"
          :data="lineItemsData"
          :isHazmat="isHazmat"
          :completeData="chosenLoad.line_items"
          :showTitle="screenWidth < 800"
          ref="lineItemsResponsive"
          :isReefer="isReefer"
        />
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import { mapGetters } from "vuex";
import AlertValidationMessage from "@/components/AlertValidationMessage.vue";
import LineItems from "./LineItems.vue";
import LineItemsResume from "../../../../../components/LineItemsResume.vue";
import ResponsiveLineItems from "./ResponsiveLineItems.vue";
import LineItemsFtl from "./LineItemsFTL.vue";
import LineItemsDrayage from "../../../Drayage/LineItemsDrayage.vue";
import LineItemsSkeleton from "./LineItemsSkeleton.vue";
import { errorMessage } from "../../../../../utils/ErrorMessages";
import modes from "../../../modes";
import TenderIndexedDB from "../../../../../Extend/TenderIndexedDB";

export default {
  name: "LineItemsContainer",
  mixins: [TenderIndexedDB],
  props: {
    screenWidth: Number,
    loading: Boolean,
    chosenLoad: Object,
    isHazmat: Boolean,
    currentStep: String,
    selectedCarrier: Object,
  },
  components: {
    LineItems,
    LineItemsResume,
    ResponsiveLineItems,
    LineItemsFtl,
    LineItemsDrayage,
    LineItemsSkeleton,
    AlertValidationMessage,
  },
  data() {
    /* eslint-disable max-len */
    return {
      lineItemsData: [],
      densityPopoverMessage:
        "The carrier service selected was rated based on density, no NMFC code is required for this load.",
      validNmfcPopover: "Please enter a valid NMFC code that matches the selected class.",
      lineItemsFields: [
        { label: "commodity" },
        { label: "nmfc", popover: "Test" },
        { label: "handling_units" },
        { label: "dimensions" },
        { label: "weight" },
        { label: "nfm_class" },
        { label: "packaging_unit_count" },
        { label: "packaging_units" },
      ],
      modes: [],
      indexedDBLineItems: [],
      lineItemsMessageError: [],
      key: 0,
      isReefer: false,
    };
  },
  computed: {
    ...mapGetters({
      vuexChosenLoad: "load/getChosenLoad",
    }),
    showDesktopLineItems() {
      return this.screenWidth >= 1300;
    },
    showResponsiveLineItems() {
      return this.screenWidth < 1300 && this.screenWidth >= 800;
    },
    selectedLoadLineItems() {
      if (this.indexedDBLineItems.length > 0) {
        this.key += 1;
        return this.indexedDBLineItems;
      }
      return this.chosenLoad.line_items;
    },
  },
  watch: {
    "vuexChosenLoad.line_items": {
      deep: true,
      handler(lineItems) {
        if (lineItems) {
          this.buildLineItemsData(lineItems);
        }
      },
    },
    selectedLoadLineItems: {
      deep: true,
      immediate: true,
      handler() {
        this.selectedLoadLineItems.forEach((item) => {
          item.quoteMode = this.chosenLoad.mode_id === modes.ftl.modeId ? "ftl" : "reefer";
        });
      },
    },
  },
  async created() {
    this.modes = modes;
    await this.checkLoadIdInIndexedDB(
      "quoteBook",
      "tenderLineItems",
      this.$route.params.loadId,
      this.chosenLoad.line_items
    );
    if (this.isHazmat) {
      this.lineItemsFields.push({ label: "hazardous_materials" });
    }
    this.buildLineItemsData(this.chosenLoad.line_items);
    if (this.selectedCarrier.source === "REEFER") {
      const index = this.lineItemsFields.findIndex((field) => field.label === "commodity");
      this.lineItemsFields.splice(index, 0, { label: "temperature" });
      this.isReefer = true;
    }
    await this.getNmfcClass();
  },
  methods: {
    getLineItemsReferencedComponent() {
      if (
        this.chosenLoad.mode_id === modes.ftl.modeId ||
        this.chosenLoad.mode_id === modes.reefer.modeId
      ) {
        return this.$refs.lineItemsFTL;
      }
      if (this.screenWidth > 1300) {
        return this.$refs.lineItems;
      }
      return this.$refs.lineItemsResponsive;
    },
    buildLineItemsData(lineItems) {
      this.lineItemsData = lineItems.map((lineItem) => {
        const convertedLineItem = {
          ...lineItem,
          dimensions: {
            length: lineItem.length,
            width: lineItem.width,
            height: lineItem.height,
            dimension_units: lineItem.dimension_units,
          },
          nmfcFieldEnabled: lineItem.nmfcFieldEnabled || false,
          nmfcPopover: lineItem.nmfcPopover || this.densityPopoverMessage,
        };
        if (this.isHazmat) {
          convertedLineItem.hazardous_materials = lineItem.hazardous_materials;
        }
        return convertedLineItem;
      });
    },
    async getNmfcClass() {
      if (this.$options.filters.checkPermission("get:standardize-nmfc")) {
        const requests = this.chosenLoad.line_items.map((item) => {
          const params = {
            weight: item.weight,
            weight_units: item.weight_units,
            handling_unit_count: item.handling_unit_count,
            handling_units: item.handling_units,
            length: item.length,
            height: item.height,
            width: item.width,
            dimension_units: item.dimension_units,
          };
          return this.$store.dispatch("standardizers/getNmfcStandardizers", { params });
        });
        Promise.all(requests).then((response) => {
          response.forEach((item, index) => {
            if (item.nmfc !== this.lineItemsData[index].nmfc_class) {
              this.lineItemsData[index].nmfcFieldEnabled = true;
              this.lineItemsData[index].nmfcPopover = this.validNmfcPopover;
            }
          });
        });
      }
    },
    validateLineItemsReference() {
      return this.chosenLoad.mode_id === modes.ftl.modeId ||
        this.chosenLoad.mode_id === modes.reefer.modeId
        ? this.validateLineItemsFTL()
        : this.validateLineItems();
    },
    validateLineItems() {
      this.$store.commit("load/lineItems/setTriggerValidations");
      const referencedComponent = this.getLineItemsReferencedComponent();
      const hazmatValidation = this.validationHazmat(referencedComponent);
      const nmfcValidation = this.validationNmfc(referencedComponent);
      let temperatureValidation = this.$store.getters["load/lineItems/getValidationTemperature"];
      if (this.isReefer && !temperatureValidation) {
        this.swal({
          title: `Temperature limits validation`,
          text: "Please, try again.",
          icon: "error",
          dangerMode: true,
        });
      } else {
        temperatureValidation = true;
      }
      return hazmatValidation && nmfcValidation && temperatureValidation;
    },
    async validateLineItemsFTL() {
      this.$store.commit("load/lineItems/setTriggerValidations");
      const referencedComponent = this.getLineItemsReferencedComponent();
      const hazmatValidation = this.validationHazmat(referencedComponent);
      const fieldsValidation = await referencedComponent.validateFormfields();
      this.lineItemsMessageError = referencedComponent.returnLineItemsMessageError();
      const totalLimitsMessages = this.$store.getters["load/lineItems/getLineItemsLimitMessage"];
      this.lineItemsMessageError.push(...totalLimitsMessages);
      const lineItemsLimitsValidation =
        this.$store.getters["load/lineItems/getLineItemsLimitValidation"];
      let temperatureValidation = this.$store.getters["load/lineItems/getValidationTemperature"];
      if (this.isReefer && !temperatureValidation) {
        this.swal({
          title: `Temperature limits validation`,
          text: "Please, try again.",
          icon: "error",
          dangerMode: true,
        });
      } else {
        temperatureValidation = true;
      }
      return (
        hazmatValidation && fieldsValidation && lineItemsLimitsValidation && temperatureValidation
      );
    },
    validateFTLLimits() {
      const referencedComponent = this.getLineItemsReferencedComponent();
      const ftlLimitsValidation = referencedComponent.validateFTLLimits();
      return ftlLimitsValidation;
    },
    validationHazmat(referencedComponent) {
      // if (this.chosenLoad?.mode_name?.toLowerCase() === 'drayage') {
      //   return true; // No necesitamos validación hazmat en drayage
      // }
      const isAnyItemWithHazmat = referencedComponent.findItemWithHazmatInfo(this.isHazmat);
      if (this.isHazmat) {
        if (!isAnyItemWithHazmat) {
          errorMessage(`At least one item must have hazmat checked`);
          return false;
        }
      }
      return true;
    },
    validationNmfc(referencedComponent) {
      // if (this.chosenLoad?.mode_name?.toLowerCase() === 'drayage') {
      //   return true; // No necesitamos validación hazmat en drayage
      // }
      const nmfcInfoValidation = referencedComponent.validateIfNmfcFieldwasFilled();
      if (!nmfcInfoValidation.infoWasFilled) {
        errorMessage(
          `Please fill in all NMFC information on item ${nmfcInfoValidation.itemNumber}`
        );
        return false;
      }
      return true;
    },
    findDefaultHazmatItem() {
      if (this.chosenLoad.mode_id === 'a0k1I0000005eufQAA') {
        return null;
      }
      const referencedComponent = this.getLineItemsReferencedComponent();
      const hazmatItem = referencedComponent.findDefaultHazmatItem();
      if (hazmatItem) {
        return hazmatItem;
      }
      return null;
    },
    retrieveInfoFromIndexedDBCollection(indexedLineItems) {
      const lineItemsArray = Object.values(indexedLineItems);
      this.indexedDBLineItems = lineItemsArray;
    },
    getLineItems(lineItems) {
      this.updateCollectionInIndexedDB("tenderLineItems", this.$route.params.loadId, lineItems);
      this.$store.commit("load/lineItems/setSelectedLineItems", lineItems);
    },
    updateLoadLineItems() {
      const referencedComponent = this.getLineItemsReferencedComponent();
      const requestList = referencedComponent.updateLoadLineItems();
      return requestList;
    },
  },
};
</script>

<style lang="scss" scoped>
.line-items-container {
  width: 95%;
  margin: auto;
}
.drayage-container-items {
  margin: 2.5rem  0 1.5rem 0;
}
.alert-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__drayage{
    width: 95%;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 14px;
    margin: auto;
    font-size: 0.75rem;
    font-style: italic;
    background: rgba(rgb(255, 251, 0), 0.2);
    ::v-deep .el-alert__description {
      color: $color-primary-company;
    }
    ::v-deep .el-alert__content {
      padding: 0px;
    }
    @media (max-width: 450px) {
      height: auto;
    }
  }
}
::v-deep .lineitem-container {
  width: 95%;
  margin: auto;
}
.responsive-lineitem-container {
  width: 92%;
  margin: auto;
}
.hazmat-error-notification {
  width: 95%;
}
.line-items-information {
  width: 95%;
  margin: auto;
}
::v-deep .line-items-ftl-container {
  width: 92%;
  margin: auto;
}
</style>
