<template>
  <div class="shipping">
    <el-date-picker
      :class="emptyFields['date'] === true ? 'missing-field' : ''"
      type="date"
      :placeholder="$t('stopsInfo.shippingDate')"
      v-model="shippingData.date"
      @change="handleMissingStyles($event, 'date'), emitPickupDate($event, mode)"
      :picker-options="datePickerOptions"
      :disabled="(mode === 'delivery' && isDrayage === false) || (mode === 'pickup' && isDrayage === true)"
      format="MM-dd-yyyy"
    ></el-date-picker>
    <label class="shipping-time-picker" for="FloatDropdown">
      {{
        mode === "pickup"
          ? $t("stopsInfo.createStopInfo.openingHours")
          : $t("stopsInfo.createStopInfo.closingHours")
      }}
      <span v-if=" isDrayage === false" class="shipping-time-picker__required">*</span>
    </label>
    <div class="time-content mb-2">
      <div class="time-picker-container">
        <el-time-select
          :class="emptyFields['firstHour'] === true ? 'missing-field' : ''"
          class="shipping__information--time"
          :placeholder="$t('stopsInfo.firstHour')"
          v-model="shippingData.firstHour"
          v-loading="gettingTimezoneHour"
          :picker-options="{
            start: setStartHour,
            step: '00:30',
            end: '23:50',
          }"
          @change="handleMissingStyles($event, 'firstHour')"
          :disabled="mode === 'pickup' && isDrayage === true"
        >
        </el-time-select>
        -
        <el-time-select
          :class="emptyFields['secondHour'] === true ? 'missing-field' : ''"
          class="shipping__information--time"
          :placeholder="$t('stopsInfo.secondHour')"
          v-model="shippingData.secondHour"
          :picker-options="{
            start: secondHour,
            step: '00:30',
            end: '23:50',
          }"
          @change="handleMissingStyles($event, 'secondHour')"
          :disabled="!shippingData.firstHour"
        >
        </el-time-select>
      </div>
      <div
        class="space-block-delivery"
        v-if="showSpaceToAlert && mode === 'delivery' && screenWidth > 800"
      >
        .
      </div>
      <el-alert
        v-if="mode === 'pickup' && isDrayage"
        class="time-picker-container__alert-drayage"
        :closable="false"
      >
        Pickup date depends on the port schedule
      </el-alert>
      <el-alert
        v-if="localTimeAlert && mode === 'pickup' &&  isDrayage === false"
        class="time-picker-container__alert"
        :closable="false"
      >
        Please be aware that same-day pickups requested after 3:30 p.m. may be rescheduled for the
        next day!
      </el-alert>
    </div>
    <div v-if="!(isDrayage && mode === 'pickup')">
      <RCInput
      class="shipping__text-field reference-input"
      type="text"
      :label="
        mode === 'pickup' ? $t('stopsInfo.shippingReferences') : $t('stopsInfo.receivingReferences')
      "
      :floatLabel="true"
      v-model="shippingData.references"
      @input="handleMissingStyles($event, 'references'), emitReferences()"
    />
  </div>
    <RCTextArea
      class="shipping__text-field"
      :label="$t('stopsInfo.instructions')"
      :floatLabel="true"
      v-model="shippingData.instructions"
      :maxlength="limitIntructionsCharacters ? 57 : null"
      @input="handleMissingStyles($event, 'instructions'), emitReferences()"
    />
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
import Moment from "moment";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import onReSize from "@/Extend/onResize";
import RCInput from "../../../../components/RCComponents/CustomRCInput.vue";
import RCTextArea from "../../../../components/RCComponents/CustomRCTextarea.vue";
import ValidateEmptyFields from "../../../../utils/validateEmptyFields";

export default {
  name: "ShippingInfo",
  mixins: [onReSize],
  props: {
    limitIntructionsCharacters: Boolean,
    shippingInfo: {
      type: Object,
      required: false,
    },
    mode: String,
    isDrayage: Boolean,
    transitTime: {
      type: String,
      required: false,
    },
    references: Object,
    selectedCarrier: Object,
  },
  components: {
    RCInput,
    RCTextArea,
  },
  data() {
    return {
      disableToday: false,
      datePickerOptions: {
        disabledDate: (date) => {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return (
            date < today ||
            date.getDay() === 6 ||
            date.getDay() === 0 ||
            (this.disableToday && date.getTime() === today.getTime())
          );
        },
      },
      shippingData: {
        date: "",
        firstHour: "",
        secondHour: "",
        references: "",
        instructions: "",
      },
      emptyFields: {
        date: false,
        firstHour: false,
        secondHour: false,
      },
      chosenLoad: null,
      selectedPickupDate: "",
      gettingTimezoneHour: false,
      currentTimezoneTime: "",
    };
  },
  computed: {
    ...mapGetters({
      chosenPickupDate: "load/tender/getChosehPickupDate",
      showSpaceToAlert: "load/tender/getLocalTimeAlert",
    }),
    setStartHour() {
      return "07:00";
    },
    secondHour() {
      if (this.shippingData.firstHour) {
        const hours = this.shippingData.firstHour.split(":")[0];
        const secondHour = parseInt(hours, 10) + 2;
        const secondHourString = secondHour < 10 ? `0${secondHour}` : secondHour;
        return `${secondHourString}:${this.shippingData.firstHour.split(":")[1]}`;
      }
      return "00:00";
    },
    localTimeAlert() {
      if (!this.currentTimezoneTime) {
        return false;
      }
      const today = new Date();
      const todayFormated = this.formatDate(today, "YYYY-MM-DD");
      const selectedPickupDateFormated = this.formatDate(this.selectedPickupDate, "YYYY-MM-DD");
      if (selectedPickupDateFormated === todayFormated && this.currentTimezoneTime >= "15:30") {
        this.$store.commit("load/tender/setisLocalTimeAlert", true);
        return true;
      }
      this.$store.commit("load/tender/setisLocalTimeAlert", false);
      return false;
    },
  },
  watch: {
    chosenPickupDate: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value && this.mode === "delivery") {
          let newDate = null;
          // Check if its truck hours or carrier days
          const newTransitTime = this.transitTime && this.transitTime.includes("hours")
            ? Math.ceil(parseInt(this.transitTime.split(" ")[0], 10) / 24)
            : this.transitTime;
          // Check if there is transit time
          if (newTransitTime > 0) {
            // Get new delivery date without weekends
            newDate = this.checkWeekendAndTransitTime(value.replace(/-/g, "/"), newTransitTime);
          } else {
            newDate = value;
          }
          this.shippingData.date = this.formatDate(newDate, "YYYY-MM-DD").replace(/-/g, "/");
        }
      },
    },
    shippingData: {
      immediate: true,
      deep: true,
      handler() {
        if (this.mode === "pickup" && this.shippingData.date !== this.selectedPickupDate) {
          this.shippingData.firstHour = "";
          this.shippingData.secondHour = "";
          this.selectedPickupDate = this.shippingData.date;
        }
      },
    },
  },
  async created() {
    this.shippingData.references = this.references.references;
    this.shippingData.instructions = this.references.instructions;
    const today = new Date();
    const todayFormated = this.formatDate(today, "YYYY-MM-DD");
    await this.getTimeZone();
    this.chosenLoad = this.$store.getters["load/getChosenLoad"];
    if (this.mode === "pickup") {
      let expectedDate = this.chosenLoad.stops.find((stop) => stop.is_pickup).expected_date;
      if (todayFormated > expectedDate) {
        expectedDate = todayFormated;
      }
      this.shippingData.date = expectedDate;
      // Check if date is in weekend and get next weekday
      this.shippingData.date = this.formatDate(this.getNextWeekday(this.shippingData.date));
      this.selectedPickupDate = this.formatDate(this.shippingData.date);
      this.emitPickupDate(this.shippingData.date, this.mode);
    } else if (this.mode === "delivery" && this.chosenLoad.mode_id === 'a0k1I0000005eufQAA') {
      this.shippingData.date = '';
    }

    if (this.$route.query.pickup) {
      this.checkEasyPostPickupRules("UPSN", "11:00");
      this.checkEasyPostPickupRules("FDEG", "11:00");
      this.checkEasyPostPickupRules("USPS", "02:00");
    }
  },
  methods: {
    emitReferences() {
      const payload = { ...this.shippingData };
      delete payload.instructions;
      this.$emit("references", { ...payload, mode: this.mode });
    },
    validateHoursByMode(hours) {
      let currentHours = parseInt(hours, 10);
      const chosenLoad = this.$store.getters["load/getChosenLoad"];
      if (chosenLoad) {
        const modeName = chosenLoad.mode_name || chosenLoad.name;
        if (modeName.toLowerCase() === "ltl") {
          for (let i = 0; i < 3; i += 1) {
            if (currentHours === 23) {
              currentHours = 0;
            } else {
              currentHours += 1;
            }
          }
        } else {
          for (let i = 0; i < 4; i += 1) {
            if (currentHours === 23) {
              currentHours = 0;
            } else {
              currentHours += 1;
            }
          }
          currentHours += 4;
        }
      }
      return currentHours < 10 ? `0${currentHours}` : currentHours;
    },
    validateFormfields() {
      if (this.mode === "pickup" && this.isDrayage) {
        this.$emit("input", this.shippingData);
        return true;
      }

      const validationObject = { ...this.shippingData };
      delete validationObject.references;
      delete validationObject.instructions;
      const validatedForm = ValidateEmptyFields(validationObject);
      this.resetEmptyFieldsValues();
      validatedForm.emptyFields.forEach((field) => {
        this.emptyFields[field] = true;
      });

      if (validatedForm.validation) {
        this.$emit("input", this.shippingData);
      }
      return validatedForm.validation;
    },
    resetEmptyFieldsValues() {
      const keys = Object.keys(this.emptyFields);
      keys.forEach((key) => {
        this.emptyFields[key] = false;
      });
    },
    handleMissingStyles(value, attribute) {
      if (value) {
        this.emptyFields[attribute] = false;
      } else {
        this.emptyFields[attribute] = true;
      }
    },
    emitPickupDate(value, mode) {
      if (mode === "pickup") {
        this.$store.commit("load/tender/setChosenPickupDate", value);
      }
    },
    checkEasyPostPickupRules(scac, time) {
      const today = new Date();
      const todayFormated = this.formatDate(today, "YYYY-MM-DD");
      const selectedPickupDateFormated = this.formatDate(this.selectedPickupDate, "YYYY-MM-DD");
      if (
        this.selectedCarrier.scac === scac &&
        selectedPickupDateFormated === todayFormated &&
        this.currentTimezoneTime >= time
      ) {
        const dateNextWeekday = this.getNextWeekday(today);
        let newDate = dayjs(dateNextWeekday).add(1, "day");
        if (newDate.day() === 6) {
          newDate = newDate.add(2, "day");
        } else if (newDate.day() === 0) {
          newDate = newDate.add(1, "day");
        }
        this.shippingData.date = newDate;
        this.disableToday = true;
      }
    },
    async getTimeZone() {
      this.gettingTimezoneHour = true;
      let params;
      if (this.shippingInfo.shipping_address) {
        params = {
          address_city: this.shippingInfo.shipping_address.city,
          zip_code: this.shippingInfo.shipping_address.postal_code,
          state_code: this.shippingInfo.shipping_address.state_code,
          country_code: this.shippingInfo.shipping_address.country_code,
        };
      } else {
        params = {
          address_city: this.shippingInfo.city,
          zip_code: this.shippingInfo.zip_code,
          state_code: this.shippingInfo.state_code,
          country_code: this.shippingInfo.country_code,
        };
      }
      const response = await this.$store.dispatch("time/getTimeZone", params);
      this.currentTimezoneTime = Moment.parseZone(response["actual date"]).format("HH:mm");
      this.gettingTimezoneHour = false;
    },
    sumTime(time) {
      // This function adds 2 hours to the current timezone time
      const [hours, minutes] = time.split(":");
      const integerMinutes = parseInt(minutes, 10);
      const parsedMinutes = integerMinutes >= 30 ? "00" : "30";
      const parsedHours =
        parsedMinutes === "00" ? parseInt(hours, 10) + 2 : parseInt(hours, 10) + 1;
      const stringHours = parsedHours < 10 ? `0${parsedHours}` : parsedHours;
      return `${stringHours}:${parsedMinutes}`;
    },
    checkWeekendAndTransitTime(date, transitTime) {
      // Check if ther is no more transit time to add
      if (transitTime === 0) {
        return !this.checkIfIsWeekend(date) ? date : this.getNextWeekday(date);
      }
      // Check if there is transit time to add and if the date is in the weekend
      if (this.checkIfIsWeekend(date) && transitTime > 0) {
        const dateNextWeekday = this.getNextWeekday(date);
        const newDate = dayjs(dateNextWeekday).add(1, "day");
        return this.checkWeekendAndTransitTime(newDate, transitTime - 1);
      }
      // Check if there is transit time to add and if the date is a weekday
      const newDate = dayjs(date).add(1, "day");
      return this.checkWeekendAndTransitTime(newDate, transitTime - 1);
    },
    getNextWeekday(date) {
      let newDate = date;
      // Check if date is on weekend and get next weekday
      if (this.checkIfIsWeekend(date)) {
        newDate = dayjs(date).add(dayjs(date).day() === 6 ? 2 : 1, "day");
      }
      return newDate;
    },
    checkIfIsWeekend(date) {
      return dayjs(date).day() === 6 || dayjs(date).day() === 0;
    },
    formatDate(date, type) {
      return dayjs(date).format(type);
    },
  },
};
</script>

<style lang="scss" scoped>
.shipping {
  &__text-field {
    margin: 10px 0px;
  }
  &__information {
    width: 100%;
    border-radius: 14px;
    border: 1px solid $color-border-container;
    margin: 18px auto;
    padding-left: 10px;
    background: $color-white;
    &--time {
      width: 44% !important;
    }
    &:focus {
      outline: none;
    }
  }
}
.shipping-time-picker {
  margin: 10px auto auto 0px;
  color: #9d9d9d;
  font-size: 0.75rem;
  &__required {
    color: #f00;
  }
}

.time-picker-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  &__alert {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 14px;
    margin-bottom: 0px;
    font-size: 0.75rem;
    font-style: italic;
    background: rgba(rgb(255, 251, 0), 0.2);
    ::v-deep .el-alert__description {
      color: $color-primary-company;
    }
    ::v-deep .el-alert__content {
      padding: 0px;
    }
  }
  &__alert-drayage{
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 14px;
    margin-bottom: 0px;
    font-size: 0.75rem;
    font-style: italic;
    background: rgba(rgb(255, 251, 0), 0.2);
    ::v-deep .el-alert__description {
      color: $color-primary-company;
    }
    ::v-deep .el-alert__content {
      padding: 0px;
    }
  }
}
.space-block-delivery {
  height: 70px;
  background: transparent;
  color: transparent;
}
.missing-field {
  background-color: $missing-fields;
}
.shipping__information {
  @include font-standart-text;
  margin: 12px auto;
}

.input-container {
  width: 100%;
  margin-top: 10px;
  &__input {
    width: 100%;
    justify-content: space-between;
    border: 1px solid $color-border-container;
    height: 40px;
    border-radius: 14px;
    padding: 5px;
    padding-left: 10px;
    outline: none;
  }
}
.reference-input {
  margin-top: 20px;
}
::v-deep {
  .missing-field > input {
    background-color: $missing-fields;
  }
  .missing-field .floatlabel-input__input {
    background-color: $missing-fields;
  }
  .el-loading-mask {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 11px;
  }
  .input-container input {
    @include font-standart-text;
  }
  .input-container input {
    @include font-standart-text;
  }
  .floatlabel-input__input {
    @include font-standart-text;
  }
  @media (max-width: 800px) {
    .floatlabel-input__label {
      font-size: 0.875rem;
    }
    .floatlabel {
      font-size: 0.75rem;
    }
  }
  .el-input__inner {
    @include font-standart-text;
  }
}
::v-deep .el-date-table td.today span {
  color: red;
}
</style>
