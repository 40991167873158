<template>
  <div class="contact-detail" v-if="contactInfo">
    <i class="ion ion-person-outline inline-icon"></i>
    <i
      @click="toggleModalVisibility"
      class="ion ion-create-outline inline-icon edit-contact"
      v-if="selectedAccount && !disableModification"
    ></i>
    <span>{{ `${contactInfo.first_name} ${contactInfo.last_name}` }}</span>
    <br />
    <i class="ion ion-mail-outline inline-icon"></i>
    <span>{{ contactInfo.email }}</span>
    <br />
    <i class="ion ion-call-outline inline-icon"></i>
    <span>{{ formatPhoneNumber(contactInfo.phone) }}</span>
    <b-modal
      class="account-edit-modal"
      v-model="showContactModal"
      hide-header
      hide-footer
      size="md"
      centered
      no-close-on-backdrop
    >
      <SelectContactModal
        @closeModal="toggleModalVisibility"
        @updateContact="updateContact"
        :chosenContact="contactInfo"
        :contactsByAccount="contactsByAccount"
        :selectedAccount="selectedAccount"
      />
    </b-modal>
  </div>
</template>

<script>
import FormatPhoneNumber from "@/Extend/FormatPhoneNumber";
import SelectContactModal from "../../shared/AccountCreation/SelectContactModal.vue";

export default {
  name: "ContactDetail",
  props: {
    contactInfo: Object,
    contactsByAccount: Array,
    selectedAccount: Object,
    disableModification: Boolean,
  },
  mixins: [FormatPhoneNumber],
  components: {
    SelectContactModal,
  },
  data() {
    return {
      showContactModal: false,
    };
  },
  methods: {
    updateContact(data) {
      this.$emit("updateContact", data);
    },
    toggleModalVisibility() {
      this.showContactModal = !this.showContactModal;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/components/Modal.scss";

.contact-detail {
  position: relative;
  color: $color-primary-company;
  margin: 0px auto 10px auto;
  border-bottom: 1px solid $color-border-container;
  padding-bottom: 10px;
  overflow-wrap: anywhere;
  @include font-standart-text;
  & span {
    margin-left: 2px;
  }
}

.edit-contact {
  position: absolute;
  right: 0px;
  top: -50px;
  cursor: pointer;
}
::v-deep .modal-content {
  width: 100%;
  @media (max-width: 500px) {
    width: 60%;
  }
}
</style>
