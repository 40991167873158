var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shipping"},[_c('el-date-picker',{class:_vm.emptyFields['date'] === true ? 'missing-field' : '',attrs:{"type":"date","placeholder":_vm.$t('stopsInfo.shippingDate'),"picker-options":_vm.datePickerOptions,"disabled":(_vm.mode === 'delivery' && _vm.isDrayage === false) || (_vm.mode === 'pickup' && _vm.isDrayage === true),"format":"MM-dd-yyyy"},on:{"change":function($event){_vm.handleMissingStyles($event, 'date'), _vm.emitPickupDate($event, _vm.mode)}},model:{value:(_vm.shippingData.date),callback:function ($$v) {_vm.$set(_vm.shippingData, "date", $$v)},expression:"shippingData.date"}}),_c('label',{staticClass:"shipping-time-picker",attrs:{"for":"FloatDropdown"}},[_vm._v(" "+_vm._s(_vm.mode === "pickup" ? _vm.$t("stopsInfo.createStopInfo.openingHours") : _vm.$t("stopsInfo.createStopInfo.closingHours"))+" "),( _vm.isDrayage === false)?_c('span',{staticClass:"shipping-time-picker__required"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"time-content mb-2"},[_c('div',{staticClass:"time-picker-container"},[_c('el-time-select',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.gettingTimezoneHour),expression:"gettingTimezoneHour"}],staticClass:"shipping__information--time",class:_vm.emptyFields['firstHour'] === true ? 'missing-field' : '',attrs:{"placeholder":_vm.$t('stopsInfo.firstHour'),"picker-options":{
          start: _vm.setStartHour,
          step: '00:30',
          end: '23:50',
        },"disabled":_vm.mode === 'pickup' && _vm.isDrayage === true},on:{"change":function($event){return _vm.handleMissingStyles($event, 'firstHour')}},model:{value:(_vm.shippingData.firstHour),callback:function ($$v) {_vm.$set(_vm.shippingData, "firstHour", $$v)},expression:"shippingData.firstHour"}}),_vm._v(" - "),_c('el-time-select',{staticClass:"shipping__information--time",class:_vm.emptyFields['secondHour'] === true ? 'missing-field' : '',attrs:{"placeholder":_vm.$t('stopsInfo.secondHour'),"picker-options":{
          start: _vm.secondHour,
          step: '00:30',
          end: '23:50',
        },"disabled":!_vm.shippingData.firstHour},on:{"change":function($event){return _vm.handleMissingStyles($event, 'secondHour')}},model:{value:(_vm.shippingData.secondHour),callback:function ($$v) {_vm.$set(_vm.shippingData, "secondHour", $$v)},expression:"shippingData.secondHour"}})],1),(_vm.showSpaceToAlert && _vm.mode === 'delivery' && _vm.screenWidth > 800)?_c('div',{staticClass:"space-block-delivery"},[_vm._v(" . ")]):_vm._e(),(_vm.mode === 'pickup' && _vm.isDrayage)?_c('el-alert',{staticClass:"time-picker-container__alert-drayage",attrs:{"closable":false}},[_vm._v(" Pickup date depends on the port schedule ")]):_vm._e(),(_vm.localTimeAlert && _vm.mode === 'pickup' &&  _vm.isDrayage === false)?_c('el-alert',{staticClass:"time-picker-container__alert",attrs:{"closable":false}},[_vm._v(" Please be aware that same-day pickups requested after 3:30 p.m. may be rescheduled for the next day! ")]):_vm._e()],1),(!(_vm.isDrayage && _vm.mode === 'pickup'))?_c('div',[_c('RCInput',{staticClass:"shipping__text-field reference-input",attrs:{"type":"text","label":_vm.mode === 'pickup' ? _vm.$t('stopsInfo.shippingReferences') : _vm.$t('stopsInfo.receivingReferences'),"floatLabel":true},on:{"input":function($event){_vm.handleMissingStyles($event, 'references'), _vm.emitReferences()}},model:{value:(_vm.shippingData.references),callback:function ($$v) {_vm.$set(_vm.shippingData, "references", $$v)},expression:"shippingData.references"}})],1):_vm._e(),_c('RCTextArea',{staticClass:"shipping__text-field",attrs:{"label":_vm.$t('stopsInfo.instructions'),"floatLabel":true,"maxlength":_vm.limitIntructionsCharacters ? 57 : null},on:{"input":function($event){_vm.handleMissingStyles($event, 'instructions'), _vm.emitReferences()}},model:{value:(_vm.shippingData.instructions),callback:function ($$v) {_vm.$set(_vm.shippingData, "instructions", $$v)},expression:"shippingData.instructions"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }